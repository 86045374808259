<template>
  <v-container class="pa-16">
    <v-data-table
      :headers="headers"
      :items="clients"
      class="elevation-0"
      disable-pagination
      hide-default-footer
      @click:row="editItem" >
      <template v-slot:no-data>
        Aucune erreur
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title id="toolbar-title">Erreurs</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="418px">
            <v-card>
              <v-card-title class="justify-space-between">
                <span class="headline">{{ formTitle }}</span>
                <v-btn color="black" dark icon right @click="close">
                  <v-icon  color="#B2BBAB">mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-form ref="form" style="width: 100%" v-model="isValid">
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="agent"
                          label="Agent"
                          v-model="editedItem.agent"
                          :items="agents"
                          item-text="name"
                          item-value="id"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="operation"
                          label="Opération"
                          v-model="editedItem.operation"
                          :items="operations"
                          item-text="name"
                          item-value="id"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <AutoComplete
                          id="dossiers"
                          label="Dossiers"
                          v-model="editedItem.dossier"
                          :items="dossiers"
                          item-text="name"
                          item-value="id"
                        />
                      </v-col>
                      <v-col class="pa-0" cols="12" md="12" sm="6">
                        <TextInput
                          id="duration"
                          label="Durée"
                          v-model="editedItem.duration"
                          :items="times"
                          item-text="name"
                          item-value="id"
                        />
                      </v-col>
                    </v-form>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="btn-actions">
                <Button
                  action_name="Continuer"
                  color="#86CC26"
                  @clicked="save(true)"
                  v-if="!showEditButton"
                />
                <Button
                  action_name="Ajouter"
                  color="#11887E"
                  text
                  @clicked="save(false)"
                  v-if="!showEditButton"
                />
                <Button
                  action_name="Modifier"
                  color="#11887E"
                  text
                  @clicked="update(false)"
                  v-if="showEditButton"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.failed="{ item }" style="max-width: 50px;">
        <span style="max-width: 100px;">
          {{ item.failed.substring(0, 250) }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon class="mr-2" small @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon class="mr-2" small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import { getAllUploads, synchronizeUpload } from "@/services/uploads.api";
import { Button, TextInput, AutoComplete } from "@/components/base";
import { addClient } from "@/services/clients.api";
import { getAllUsers } from "@/services/users.api";
import { getAllOperations } from "@/services/operations.api";
import { getAllDossiers } from "@/services/dossiers.api";
import times from "@/assets/data/times.json";

export default {
  components: {
    AutoComplete,
    Button,
    TextInput
  },
  data: () => ({
    times: times,
    agents: [],
    operations: [],
    isValid: true,
    showEditButton: false,
    dialog: false,
    headers: [
      {
        text: "failed",
        align: "start",
        value: "failed"
      },
      { text: "Line", value: "failed_line" },
      { text: "Actions", value: "actions", sortable: false }
    ],
    clients: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    dossiers: []
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nouvelle ligne" : "Modifier ligne";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    deleteItem() {},
    openAddDialog() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.dialog = true;
    },
    initialize() {
      getAllDossiers().then(response => {
        this.dossiers = response.data;
      });
      getAllOperations().then(response => {
        this.operations = response.data;
      });
      getAllUsers().then(response => {
        this.agents = response.data;
      });
      getAllUploads().then(response => {
        this.clients = response.data;
      });
    },
    close() {
      this.agentDialog = false;
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.showEditButton = false;
      });
    },
    update() {
      synchronizeUpload(this.editedItem).then(() => {
        this.showEditButton = false;
        this.close();
        this.$swal.fire({ title: "Client modifier avec succès", });
      });
    },
    editItem() {
      // var failedObject = JSON.parse(item.failed);
      this.showEditButton = true;
      //this.editedIndex = this.clients.indexOf(item);
      //this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    save(dialogStat) {
      if (!this.isValid) {
        this.$refs.form.validate();
        return;
      }
      addClient(this.editedItem).then(response => {
        this.editedItem.id = response.data.id;
        this.editedItem.status = response.data.status;
        this.clients.push(this.editedItem);
        this.close();
        this.$refs.form.reset();
        this.dialog = dialogStat;
        this.$swal.fire({ title: "Client ajouté avec succès", });
      });
    }
  }
};
</script>
<style scoped></style>
